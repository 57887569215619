import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Login } from 'src/Models/loginModel';
import { DataService } from './data.service';
import { Globle } from 'src/app/Shared/global';
import { PaginationModel } from 'src/Models/paginationModel';


@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(private _data: DataService) { }
  getEmailTemplate(providerId: number,userTypeId: number): Observable<any> {    
    return this._data.post(`EmailTemplate/GetEmailTemplate/${providerId}/${userTypeId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  editEmailTemplate(Id: number): Observable<any> {
    return this._data.post(`EmailTemplate/EditEmailTemplate/${Id}`)
      .pipe(map(response => {
        return response;
      }));
  }

  updateemailTemplate(emailTemplate): Observable<any> {
    return this._data.post(`EmailTemplate/UpdateEmailTemplate`, emailTemplate)
      .pipe(map(response => {
        return response;
      }));
  }

}
