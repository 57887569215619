import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { movetotop } from 'src/app/Shared/commonMethods';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  changepasswordForm: FormGroup;
  appModel: any;
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  statusCode: number = 0;
  userModel = new UserModel();

  showoldeye: boolean = false;
  showold: boolean = false;
  showneweye: boolean = false;
  shownew: boolean = false;
  showconfirmeye: boolean = false;
  showconfirm: boolean = false;
  SelectedTool: string;
  constructor(
    private _fb: FormBuilder,
    private UserService: UsersService,
    private activedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private titleService: Title,
    private globle: Globle
  ) {
    this.changepasswordForm = this._fb.group({
      id: 0,
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
      modifiedBy: this.globle.cUser.id
    });
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  ngOnInit() {
    movetotop();
    this.titleService.setTitle('My Profile');
    this.Getuser();
  }

  Getuser(): void {
    this.UserService.GetUserFindByUserID(this.globle.cUser.id).subscribe(
      data => {
        if (this.userModel != null) {
          this.userModel = data;
        }
        this.unblockDocument();
      }
    );
  }
  savepassword(changepasswordForm: any) {
    if (
      changepasswordForm.value['oldpassword'] == null ||
      changepasswordForm.value['oldpassword'] == undefined ||
      changepasswordForm.value['oldpassword'].trim() == ''
    ) {
      this.showMessage('Please enter old password.', 'Required', 'error');
      return;
    } else {
      if (
        changepasswordForm.value['oldpassword'].trim() !=
        this.userModel.password.trim()
      ) {
        this.showMessage(
          'Incorrect old password.',
          'Invalid Password',
          'error'
        );
        return;
      }
    }
    if (
      changepasswordForm.value['newpassword'] == null ||
      changepasswordForm.value['newpassword'] == undefined ||
      changepasswordForm.value['newpassword'].trim() == ''
    ) {
      this.showMessage('Please enter new password.', 'Required', 'error');
      return;
    }
    if (
      changepasswordForm.value['confirmpassword'] == null ||
      changepasswordForm.value['confirmpassword'] == undefined ||
      changepasswordForm.value['confirmpassword'].trim() == ''
    ) {
      this.showMessage('Please enter confirm password.', 'Required', 'error');
      return;
    }
    if (
      changepasswordForm.value['confirmpassword'].trim() !=
      changepasswordForm.value['newpassword'].trim()
    ) {
      this.showMessage(
        'Password and Confirm password not matched',
        'Invalid Password',
        'error'
      );
      return;
    }
    if (changepasswordForm.value['newpassword'].length <= 5) {
      this.showMessage(
        'Password must contain at least 6 character',
        'Password To Short',
        'error'
      );
      return;
    }
    if (
      this.isPasswordFormat(changepasswordForm.value['newpassword']) == null
    ) {
      this.showMessage(
        'password should contain at least 1 uppercase, lowercase, digit and special character',
        'Password Pattern',
        'error'
      );
      return;
    }
    this.blockDocument();
    changepasswordForm.value['id'] = this.globle.cUser.id;
    this.userModel.id = changepasswordForm.value['id'];
    this.userModel.password = changepasswordForm.value['newpassword'];
    this.userModel.modifiedById = this.globle.cUser.id;
    this.UserService.updatepassword(this.userModel).subscribe(
      data => {
        if (data.status == 'Success') {          
          changepasswordForm.reset();
          this.showMessage(
            'Password changed successfully',
            'Password changed',
            'success'
          );
          this.unblockDocument();
        } else {
          this.showMessage(data.status, 'Error', 'error');
          this.unblockDocument();
        }
        this.Getuser();
      },
      error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      }
    );
  }

  isPasswordFormat(control: any) {
    let EMAIL_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (control != '' && (control.length > 5 && EMAIL_REGEXP.test(control))) {
      return { 'Password pattern are valid': true };
    }

    return null;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    } else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (
      control.value != '' &&
      (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))
    ) {
      return { 'Please provide a valid email': true };
    }

    return null;
  }
 
  changeoldpass($event) {
    if ($event.target.value != '' && $event.target.value != null) {
      this.showoldeye = true;
    } else {
      this.showoldeye = false;
    }
  }
  hideshowoldpassword() {
    this.showold = !this.showold;
  }
  changenewpass($event) {
    if ($event.target.value != '' && $event.target.value != null) {
      this.showneweye = true;
    } else {
      this.showneweye = false;
    }
  }
  hideshownewpassword() {
    this.shownew = !this.shownew;
  }
  changeconfirmpass($event) {
    if ($event.target.value != '' && $event.target.value != null) {
      this.showconfirmeye = true;
    } else {
      this.showconfirmeye = false;
    }
  }
  hideshowconfirmpassword() {
    this.showconfirm = !this.showconfirm;
  }
}
