import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { UserType } from 'src/app/Shared/AppEnums';
import { DashboardService } from 'src/Services/dashboard.service';
import { Dashboard } from 'src/Models/dashboard';
import { movetotop } from 'src/app/Shared/commonMethods';
import { UserRightsService } from 'src/Services/userrights.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userType = '';
  providerId = 0;
  dashboardData = new Dashboard();
  menuList: Array<any>;
  userGroupId = 0;  
  constructor(
    private titleService: Title,
    private globle: Globle,
    private dashboardService: DashboardService, private userRightsService: UserRightsService
  ) {

  }

  ngOnInit() {
    movetotop();
    this.titleService.setTitle('Dashboard');
    this.userType = UserType[this.globle.cUser.userTypeId];
    this.providerId = this.globle.cUser.providerId;
    this.userGroupId = this.globle.cUser.userGroupId;
    this.DashboardCount();
    this.getMenuByGroupId(this.globle.cUser.userGroupId);
  }

  DashboardCount() {
    this.dashboardService.DashboardCount({
      UserTypeId: this.globle.cUser.userTypeId,
      ProviderId: this.globle.cUser.providerId ? this.globle.cUser.providerId : 0,
      LocationId: this.globle.cUser.userGroupId !== 3 ? this.globle.cUser.locationId : null,
      UserGroupId: this.userGroupId
    }).subscribe(data => {
      if (data) {
        this.dashboardData = data.dashboard;
      }
    });
  }

  
  getMenuByGroupId(userGroupId: number): void {
    this.userRightsService.getMenuByGroupId(userGroupId).subscribe(
      data => {
        this.menuList = data;
      });
  }

}
