import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ProviderService } from 'src/Services/provider.service';
import { PaginationModel } from 'src/Models/paginationModel';
import { ProviderModel } from 'src/Models/providerModel';
import { movetotop } from 'src/app/Shared/commonMethods';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})
export class ProvidersComponent implements OnInit {
  listOfProvider: ProviderModel[];
  pagination = new PaginationModel();
  totalRecordCount: number;
  isAscending: boolean;
  upDowProviderNameCSS = '';
  upDowAddressCSS = '';
  upDowEmailIdCSS = '';
  upDowCityCSS = '';
  upDowIsActiveCSS = '';
  upDowModifiedCSS = '';
  isloading = false;
  searchTextChanged = new Subject<string>();
  constructor(
    private router: Router,
    private providerService: ProviderService,
    private titleService: Title
  ) {

    this.listOfProvider = [];
    this.pagination.pageNumber = 1;
    this.pagination.pageSize = 10;
    this.pagination.orderBy = 'Name';
    this.totalRecordCount = -1;
    this.isAscending = true;
  }


  ngOnInit() {
    movetotop();
    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap(search => this.providerService.GetProviders(this.pagination))
    ).subscribe((data) => {
      this.isloading = false;
      this.listOfProvider = data;
      if (this.listOfProvider != null) {
        if (this.listOfProvider.length > 0) {
          this.totalRecordCount = this.listOfProvider.length;
        } else {
          this.totalRecordCount = 0;
        }

      }
    });

    this.titleService.setTitle('Providers');
    this.GetListOfProvider();
  }

  GetListOfProvider() {
    this.isloading = true;
    this.providerService.GetProviders(this.pagination).subscribe(data => {
      this.isloading = false;
      this.listOfProvider = data;
      if (this.listOfProvider != null) {
        if (this.listOfProvider.length > 0) {
          this.totalRecordCount = this.listOfProvider.length;
        } else {
          this.totalRecordCount = 0;
        }
      }
      this.isloading = false;
    }, error => {
      this.isloading = false;
    });
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.pagination.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "Name": this.upDowProviderNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "Address": this.upDowAddressCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "EmailID": this.upDowEmailIdCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'City': this.upDowCityCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'IsActive': this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'modifiedByName': this.upDowModifiedCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetListOfProvider();

  }

  keywordFilters($event) {
    this.isloading = true;
    this.pagination.keywordFilter = $event.target.value;
    this.searchTextChanged.next($event.target.value);
  }


  cleanCssClass() {
    this.upDowProviderNameCSS = '';
    this.upDowAddressCSS = '';
    this.upDowEmailIdCSS = '';
    this.upDowCityCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowModifiedCSS = '';
  }

  editUser(id: any) {
    this.router.navigate(['admin/manage/' + id]);
  }

  viewProviderDivision(id: any) {
    this.router.navigate(['admin/manage/' + id]);
  }

  pageChanged($event: any) {
    this.pagination.pageNumber = parseInt($event.page);
    this.GetListOfProvider();
  }
	editProvider(id: any) {
    this.router.navigate(['admin/providers/edit/' + id]);
  }

  rateSetup(id: any) {
    this.router.navigate(['admin/rate/setup/' + id]);
  }
}
