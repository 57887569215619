import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Login } from 'src/Models/loginModel';
import { DataService } from './data.service';
import { Globle } from 'src/app/Shared/global';
import { PaginationModel } from 'src/Models/paginationModel';
import { Http, Response, Headers, URLSearchParams, RequestOptions, ResponseContentType, RequestMethod } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private _http: HttpClient, private _data: DataService, private _global: Globle, private http: Http,) {
    this.setAPIUrl();
  }
  AppUrl: string = '';
  setAPIUrl() {
    if (
      sessionStorage.getItem('BaseURL') != null &&
      sessionStorage.getItem('BaseURL') != ''
    ) {
      this.AppUrl = sessionStorage.getItem('BaseURL') || '';
    }
  }


  checkAdminUserLogin(loginuser): Observable<any> {
    return this._data.post(`Login/CheckAdminUserLogin`, loginuser)
      .pipe(map(response => {
        if (response && response.user.authToken) {

          sessionStorage.setItem('cUser', JSON.stringify(response.user));
          this._global.cUser = response.user;
        }
        return response.user;
      }));
  }

  checkAdminUserLoginByUserId(userId: number): Observable<any> {
    return this._data.post(`Login/CheckAdminUserLoginByUserId/${userId}`)
      .pipe(map(response => {
        if (response && response.user.authToken) {
          sessionStorage.setItem('cUser', JSON.stringify(response.user));
          this._global.cUser = response.user;
        }
        return response.user;
      }));
  }

  checkSubscriberUserLogin(loginuser): Observable<any> {
    return this._data.post(`Login/CheckSubscriberUserLogin`, loginuser)
      .pipe(map(response => {
        if (response && response.users) {
          return { users: response.users };
        } else {
          if (response && response.user.authToken) {
            sessionStorage.setItem('cUser', JSON.stringify(response.user));
            this._global.cUser = response.user;
          }
          return response.user;
        }
      }));

  }

  ForgotEnrollment(loginuser): Observable<any> {
    return this._data.post(`Login/ForgotEnrollment`, loginuser)
      .pipe(map(response => {
        return response;
      }));
  }

  GetUserFindByUserID(userID: number): Observable<any> {
    return this._data.get(`Users/GetUserFindByUserID`, userID)
      .pipe(map(response => {
        return response;
      }));
  }

  addupdateuser(user): Observable<any> {
    return this._data.post(`Users/AddUpdateUser`, user)
      .pipe(map(response => {
        return response;
      }));
  }

  updatepassword(user): Observable<any> {
    return this._data.post(`Users/UpdatePassword/`, user)
      .pipe(map(response => {
        return response;
      }));
  }

  GetProviderUsers(userTypeId: number, providerId: number, locationId: string, userGroupId: number,exportExcel:boolean, pagination: PaginationModel): Observable<any> {
    return this._data.post(`Users/GetProviderUser/${providerId}/${userTypeId}/${locationId}/${userGroupId}/${exportExcel}`, pagination)
      .pipe(map(response => {
        return response;
      }));
  }

  GetUserGroups(userTypeId: number): Observable<any> {
    return this._data.get(`Users/GetUserGroups`, userTypeId)
      .pipe(map(response => {
        return response;
      }));
  }

  GetProviderManagerUser(providerId: number): Observable<any> {
    return this._data.post(`Users/GetProviderManagerUser/${providerId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  forgotPassword(emailId: string): Observable<any> {
    return this._data.post(`Login/ForgotPassword/${emailId}`)
      .pipe(map(response => {
        return response;
      }))
  }

  private handleError(error: Response | any) {
    return Observable.throw(error.status);
  }

  logout() {
    sessionStorage.removeItem('cUser');
    sessionStorage.removeItem('cApp');
    // sessionStorage.removeItem('cAppHistory');
  }

  GetIPAddress(): Observable<any> {
    let fullAPIPath = 'https://jsonip.com';
    return this._data.getBypassURL(fullAPIPath)
      .pipe(map(response => {
        return response;
      }))
  }

  GetSubscriberByPaged(filterModel: any): Observable<any> {
    return this._data.post(`Users/GetSubscriberByPaged`, filterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetApplicantsByPaged(filterModel: any): Observable<any> {
    return this._data.post(`Users/GetApplicantsByPaged`, filterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetSubscriberById(id: any): Observable<any> {
    return this._data.post(`Users/GetSubscriberById/` + id, id)
      .pipe(map(response => {
        return response;
      }));
  }

  SubscriberStausFindById(id: any): Observable<any> {
    return this._data.post(`Users/SubscriberStausFindById/` + id, id)
      .pipe(map(response => {
        return response;
      }));
  }

  TransferSubscriberStausFindById(id: any): Observable<any> {
    return this._data.post(`Users/TransferSubscriberStausFindById/` + id, id)
      .pipe(map(response => {
        return response;
      }));
  }  

  UpdateSubscriber(userModel: any): Observable<any> {
    return this._data.post(`Users/UpdateSubscriber/`, userModel)
      .pipe(map(response => {
        return response;
      }));
  }

  ApplicationUpdate(model: any): Observable<any> {
    return this._data.post(`UniversalService/EnrollmentUpdate/`, model)
      .pipe(map(response => {
        return response;
      }));
    // return this._data.post(`Users/ApplicationUpdate/`, model)
    // .pipe(map(response => {
    //   return response;
    // }));
  }

  DeEnrollmentSubscriber(model: any): Observable<any> {
    return this._data.post(`UniversalService/DeEnrollmentSubscriber`, model)
      .pipe(map(response => {
        return response;
      }));
  }

  FreshUserUpdate(model: any): Observable<any> {
    return this._data.post(`UniversalService/FreshUserUpdate/`, model)
      .pipe(map(response => {
        return response;
      }));
    // return this._data.post(`Users/ApplicationUpdate/`, model)
    // .pipe(map(response => {
    //   return response;
    // }));
  }

  getCurrentEnviroment(): Observable<any> {
    return this._data.get(`Login/GetCurrentEnviroment`)
      .pipe(map(response => {
        return response;
      }));
  }

  DeletApplicationFindByApplicationId(id: any): Observable<any> {
    return this._data.post(`Users/DeletApplicationFindByApplicationId/` + id, id)
      .pipe(map(response => {
        return response;
      }));
  }

  GetApplicantsByPagedExport(filterModel: any): Observable<any> {
    return this._data.post(`Users/GetApplicantsByPagedExport`, filterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  DownloadDocument(docPath: any): Observable<any> {
    this.setAPIUrl();
    let data = 'Users/DownloadDocument';
    return this._data.downloadFile(data, docPath).pipe(
      map(
        (res) => {
          return new Blob([res.blob()], { type: 'application/octet-stream' });
        }));
  }

  GetConsentHistory(applicationHistoryId:any): Observable<any> {
    return this._data.get(`Users/GetConsentHistory`, applicationHistoryId)
      .pipe(map(response => {
        return response;
      }));
  }  

  Applicationtransfer(model: any): Observable<any> {    
    return this._data.post(`UniversalService/EnrollmentTransfer/`, model)
      .pipe(map(response => {
        return response;
      }));   
  }
  
  manuallyDeEnrollmentSubscriber(model: any): Observable<any> {
   
    return this._data.post(`UniversalService/manuallyDeEnrollmentSubscriber`, model)
      .pipe(map(response => {
        return response;
      }));
  }

  GetSnapshotandSummaryByPaged(filterModel: any): Observable<any> {
    return this._data.post(`Users/GetSnapshotandSummaryByPaged`, filterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetMonthlySnapshotandSummaryByPaged(filterModel: any): Observable<any> {
    return this._data.post(`Users/GetMonthlySnapshotandSummaryByPaged`, filterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetMonthandYearList(): Observable<any> {
    return this._data.get(`Users/GetMonthandYearList`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetInrollVSNlad(): Observable<any> {
    return this._data.get(`Users/GetInrollVSNlad`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetNladVSInrollReport(filterModel: any): Observable<any> {
    return this._data.post(`Users/GetNladVSInrollReport`, filterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  GetSubscriberDetails(id: any): Observable<any> {
    return this._data.post(`ViewDetails/GetSubscriberDetails/` + id, id)
      .pipe(map(response => {
        return response;
      }));
  }
  
}
