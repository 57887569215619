import { Component, OnInit } from '@angular/core';
import { ConsentHistoryModel } from 'src/Models/ConsentHistoryModel';
import { UsersService } from 'src/Services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Globle } from 'src/app/Shared/global';
import { Router,ActivatedRoute } from '@angular/router';
import { getIntParam, copyToClipboard } from 'src/app/Shared/commonMethods';
@Component({
  selector: 'app-consent-history',
  templateUrl: './consent-history.component.html',
  styleUrls: ['./consent-history.component.css']
})
export class ConsentHistoryComponent implements OnInit {
  listofconsents: ConsentHistoryModel[];
  isloading = false;
  applicationHistoryId:number=0;
  constructor(private router: Router,private activatedRoute: ActivatedRoute,private userService: UsersService,private globle: Globle, private toastr: ToastrService) {
   this.applicationHistoryId= getIntParam(this.activatedRoute.params, 'id');
   if (sessionStorage.getItem('consentHistoryId') != null)
       sessionStorage.removeItem('consentHistoryId')
   if (sessionStorage.getItem('applicationHistoryId') != null)
       sessionStorage.removeItem('applicationHistoryId')
   }
  ngOnInit() {
    this.GetConsentHistory();
  }
  GetConsentHistory() {
    this.isloading = true;
    this.listofconsents = [];
    this.userService.GetConsentHistory(this.applicationHistoryId).subscribe(
      data => {      
        this.isloading = false;
        this.listofconsents = data; 
      },
      error => {
        this.isloading = false;      
        console.log(error);
      }
    );
  }
  ViewConsent(consent) {
    sessionStorage.setItem('consentHistoryId', consent.consentHistoryId);
    sessionStorage.setItem('applicationHistoryId', consent.applicationHistoryId);
    this.router.navigate(['/admin/viewconsent']);
  }
  returnToList() {
    this.router.navigate(['/admin/applicants']);
  }
}