import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/Services/location.service';
import { Globle } from 'src/app/Shared/global';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportService } from 'src/Services/report.service';
import { PaginationModel } from 'src/Models/paginationModel';
import { Subject } from 'rxjs';
import { SubscriberApplicationStatus, FormType } from 'src/app/Shared/AppEnums';
import { EligibilityReport } from 'src/Models/eligibilityReport';
import { ToastrService } from 'src/app/Shared/toastr.service';
import { ApiStatus } from 'src/Models/apiStatus';

@Component({
  selector: 'app-reportdetail',
  templateUrl: './reportdetail.component.html',
  styleUrls: ['./reportdetail.component.css']
})
export class ReportdetailComponent implements OnInit {
  listOflocation;
  poviderId = 0;
  isloading: boolean;
  reportform: FormGroup;
  listSubscriber: EligibilityReport[];
  statuscount: any = {};
  totalRecordsCount = -1;
  pagination = new PaginationModel();
  listOfApiStatus: ApiStatus[];

  searchTextChanged = new Subject<string>();
  constructor(
    private locationService: LocationService,
    private globle: Globle,
    private _fb: FormBuilder,
    private reportService: ReportService,
    private toastr: ToastrService
  ) {

    this.reportform = this._fb.group({
      providerId: [this.globle.cUser.providerId],
      fromDate: [],
      todate: [],
      applicationstatus: ['All'],
      pagination: [this.pagination],
      locationId: ['0']
    });

  }

  ngOnInit() {
    this.pagination.pageSize = 10;
    this.pagination.pageNumber = 1;
    this.pagination.orderBy = "ApplicationDate Desc";
    this.pagination.whereCondition = '';
    this.poviderId = this.globle.cUser.providerId;
    this.getProviderLocationDropdown();
    this.GetEligilityReport();
    this.GetApiStatus();
  }

  getProviderLocationDropdown() {
    this.locationService.getProviderLocationDropdown(this.poviderId).subscribe(data => {
      this.isloading = false;
      this.listOflocation = data;
    }, error => {
      this.isloading = false;
    });
  }

  pageChanged($event) {
    this.pagination.pageNumber = $event.page;
    this.GetEligilityReport();
  }

  getStringApplicationStatus(id: number): any {
    return SubscriberApplicationStatus[id];
  }

  getStringApplicationType(id: number): any {
    return FormType[id];
  }

  filterReport(formValue: any) {

    if (
      (formValue.controls["fromDate"].value != null && formValue.controls["fromDate"].value != '') &&
      (formValue.controls["todate"].value == null || formValue.controls["todate"].value == '')
    ) {
      this.toastr.Error('Please select todate', 'Error');
      return;
    }

    if (
      (formValue.controls["fromDate"].value == null || formValue.controls["fromDate"].value == '') &&
      (formValue.controls["todate"].value != null && formValue.controls["todate"].value != '')
    ) {
      this.toastr.Error('Please select from date', 'Error');
      return;
    }

    if (
      (formValue.controls["fromDate"].value != null && formValue.controls["fromDate"].value != '') &&
      (formValue.controls["todate"].value != null && formValue.controls["todate"].value != '')
    ) {
      let fDate = new Date(formValue.controls["fromDate"].value);
      let tDate = new Date(formValue.controls["todate"].value);
      if (fDate > tDate) {
        this.toastr.Error('Please from date greter than todate', 'Error');
        return;
      }
    }

    this.pagination.fromDate = formValue.controls["fromDate"].value;
    this.pagination.todate = formValue.controls["todate"].value;
    this.pagination.locationId = formValue.controls["locationId"].value;

    

    this.pagination.apiStatus = formValue.controls["applicationstatus"].value;
    if (formValue.controls["applicationstatus"].value == 'All')
      this.pagination.apiStatus = '';

    if (formValue.controls["locationId"].value == '0') {
      this.pagination.locationId = null;
    }
    else {
      this.pagination.locationId = formValue.controls["locationId"].value;
    }

    this.GetEligilityReport();
  }

  GetEligilityReport() {
    this.reportService.DetailReport(this.pagination).subscribe(
      data => {

        if (data != null) {
          this.isloading = false;
          this.listSubscriber = data;
          //this.statuscount = data.item2;
          if (this.listSubscriber.length > 0) {
            this.totalRecordsCount = +this.listSubscriber[0].totalRecordCount;
          }
          else {
            this.totalRecordsCount = 0;
          }
        }
      }, error => {
        this.isloading = false;
      });
  }

  GetApiStatus() {
    this.reportService.GetApiStatus().subscribe(
      data => {
  
        if (data != null) {
          this.listOfApiStatus = data;
        }
      }, error => {
        this.isloading = false;
      });
  }

}
