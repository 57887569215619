import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { Globle } from './global';
import { movetotop } from './commonMethods';

@Injectable()
export class AuthGuard implements CanActivateChild {

    constructor(private router: Router,
        private globle: Globle
    ) {
    }

    canActivateChild() {        
        if (sessionStorage.getItem('cUser')) {
            if (this.globle.cUser == null) {
                this.globle.cUser = JSON.parse(sessionStorage.getItem('cUser'));
            }
            // if (this.globle.cApp == null) {
            //     this.globle.cApp = JSON.parse(sessionStorage.getItem('cApp'));
            // }
            
            this.globle.cApp.subscriberApplicationHistory = JSON.parse(sessionStorage.getItem('cAppHistory'));
            return true;
        }
        //this.router.navigate(['whipcityfiber/login'])        
        //this.router.navigate(['ebb/login'])
        this.router.navigate(['ebb/login'])
        return false;
    }
}