import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Shared/auth.service';
import { UsersService } from 'src/Services/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  submitted = false;
  constructor
    (
      private _fb: FormBuilder,
      private router: Router,
      private _authService: AuthService,
      private userService: UsersService,
      private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.forgotpasswordForm = this._fb.group({
      emailId: ['', [Validators.required, Validators.email]]
    })
  }

  back() {
    this.router.navigate(['/admin/login']);
  }

  get forgotpasswordFormcontrol() { return this.forgotpasswordForm.controls; }

  sendPassword() {
    
    this.submitted = true;
    if (this._authService.checkValidation(this.forgotpasswordForm)) {
      this.userService.forgotPassword(this.forgotpasswordForm.value.emailId).subscribe(
        data => {
          if (data) {
            this.toastr.success("We have sent login details to your email address.", "Success")
            this.router.navigate(['/admin/login']);
          }
        }
      );
    }
  }
}
