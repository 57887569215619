export class OnlineRegistrationModel {
    id?: any;
    firstName?: any;
    lastName?: any;
    emailId?: any;
    phone?: any;
    createdBy?: any;
    createdDate?: any;
    totalRecordCount?: any;
    conatactType?: any;
    apiStatus?: any;
    enrollStatus?: any;
    applicationHistoryId?: any;
}