import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UsersService } from 'src/Services/users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { AuthService } from 'src/app/Shared/auth.service';
import { StateService } from 'src/Services/state.service';
import { LocationService } from 'src/Services/location.service';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { PaginationModel } from 'src/Models/paginationModel';
import { ProviderZone } from 'src/Services/providerzone.services';
import { ProviderZoneModel } from 'src/Models/providerZoneModel';

@Component({
  selector: 'app-add-edit-location',
  templateUrl: './add-edit-location.component.html',
  styleUrls: ['./add-edit-location.component.css']
})
export class AddEditLocationComponent implements OnInit {
  providerId: number = 0;
  userId: number = 0;
  listOfManager: string[] = [];
  listOfStates: string[] = [];
  isloadingstates: boolean;
  pageTitle: string = 'Add Legacy Company';
  submitted: boolean;
  locationForm: FormGroup;
  zoneForm: FormGroup;
  id: number = 0;
  pagination: PaginationModel = new PaginationModel();
  listOfZone: ProviderZoneModel[];
  selectedZone: ProviderZoneModel[];

  dropdownZoneList = [];
  selectedZoneItems = [];
  dropdownZoneSettings = {};
  zoneList: any[] = [];
  addUpdateZoneModel = new ProviderZoneModel();
  @ViewChild('cancelZoneModel') closeZoneModel: ElementRef;

  constructor
    (
      private _fb: FormBuilder,
      private locationService: LocationService,
      private toastr: ToastrService,
      private userService: UsersService,
      private router: Router,
      private globle: Globle,
      private auth: AuthService,
      private stateService: StateService,
      private activatedRoute: ActivatedRoute,
      private titleService: Title,
      private providerZone: ProviderZone
    ) {
    this.locationForm = this._fb.group({
      userId: [this.userId],
      id: [this.id],
      name: ['', [Validators.required]],
      stateId: [''],
      city: [''],
      isActive: 1,
      providerId: 0,
      zone: []
    });

    this.zoneForm = this._fb.group({
      zoneName: '',
      zoneIsActive: 1
    })
  }

  get locationform() { return this.locationForm.controls; }

  ngOnInit() {
    movetotop();
    this.titleService.setTitle('Manage Legacy Company');
    this.pagination.orderBy = 'Id DESC';
    this.pagination.pageNumber = 1;
    this.pagination.pageSize = 10;
    this.providerId = this.globle.cUser.providerId;
    this.userId = this.globle.cUser.id;

    this.pagination.userId = this.userId.toString();

    this.getState();
    this.id = getIntParam(this.activatedRoute.params, 'id');
    //Edit Location
    if (this.id > 0) {
      this.pageTitle = 'Edit Legacy Company';
      this.editProviderLocation();
    }
    else
      this.getProviderZoneDropDown();
  }

  editProviderLocation() {
    this.isloadingstates = true;
    this.locationService.getProviderLocation(this.id, this.providerId, this.pagination).subscribe(
      data => {
        this.locationForm.patchValue(data[0]);
        this.selectedZone = data[0].providerZones;
        this.isloadingstates = false;
        this.getProviderZoneDropDown();
      }
    );
  }

  getState(): void {
    this.isloadingstates = true;
    this.stateService.GetStateList().subscribe(
      data => {
        this.listOfStates = data;
        this.isloadingstates = false;
      }
    );
  }

  saveLocation(locationForm: any) {
    this.submitted = true;
    if (this.auth.checkValidation(locationForm)) {
      this.locationForm.value['userId'] = this.userId;
      this.locationForm.value['providerId'] = this.providerId;
      if (this.zoneList.length == 0) {
        this.toastr.error('Please select zipcode', 'Error');
        return;
      }
      this.locationForm.value['zone'] = this.zoneList.join(',')
      this.locationForm.value['stateId'] = 1;
      this.locationForm.value['city'] = 0;

      this.locationService.addupdatelocation(this.locationForm.value).subscribe(
        data => {
          const msg = data.Id === 0 ? 'added' : 'updated';
          this.toastr.success('Legacy company ' + msg + ' successfully', 'Success');
          this.router.navigate(['/admin/locations/']);
        });
    }
  }

  cancelLocation() {
    this.router.navigate(['/admin/locations/']);
  }

  getProviderZoneDropDown(): void {
    this.isloadingstates = true;
    this.providerZone.getProviderZoneDropDown(this.providerId, this.userId, this.id).subscribe(
      data => {
        this.listOfZone = data;
        this.dropdownZoneList = [];
        if (this.listOfZone != null) {
          if (this.listOfZone.length > 0) {
            for (let iCounter = 0; iCounter < this.listOfZone.length; iCounter++) {
              this.dropdownZoneList.push(
                { "id": this.listOfZone[iCounter].id, "itemName": this.listOfZone[iCounter].zoneCode }
              );
            }
          }
        }

        this.selectedZoneItems = [];
        if (this.listOfZone != null) {
          if (this.listOfZone.length > 0) {
            if (this.selectedZone != null) {
              for (let iCounter = 0; iCounter < this.listOfZone.length; iCounter++) {
                for (let jCounter = 0; jCounter < this.selectedZone.length; jCounter++) {
                  if (this.listOfZone[iCounter].id == this.selectedZone[jCounter].id) {
                    this.selectedZoneItems.push(
                      { "id": this.listOfZone[iCounter].id, "itemName": this.listOfZone[iCounter].zoneCode }
                    );
                    this.zoneList.push(this.listOfZone[iCounter].id);
                  }
                }
              }
            }
          }
        }

        this.dropdownZoneSettings = {
          singleSelection: false,
          text: "Select Zipcode",
          selectAllText: 'Select All',
          unSelectAllText: 'Clear All',
          enableSearchFilter: true,
          enableCheckAll: true,
          badgeShowLimit: 1,
          noDataLabel: 'No Zipcode available'
        };

      }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e != element);
  }

  onItemSelect(item: any) {
    if (this.zoneList != undefined) {
      this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
    }
    this.zoneList.push(item['id']);
  }
  OnItemDeSelect(item: any) {
    if (this.zoneList != undefined) {
      this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
    }
  }
  onSelectAll(items: any) {
    this.zoneList = [];
    for (let iCounter = 0; iCounter < items.length; iCounter++) {
      this.zoneList.push(items[iCounter]['id']);
    }
  }
  onDeSelectAll(items: any) {
    this.zoneList = [];
  }

  saveZone(zoneForm: any) {
    if (this.zoneForm.value['zoneName'] == '') {
      this.toastr.error('Please enter Zipcode', 'Error');
      return;
    }
    this.addUpdateZoneModel.id = 0;
    this.addUpdateZoneModel.LocationId = 0;
    this.addUpdateZoneModel.createdBy = this.userId;
    this.addUpdateZoneModel.modifiedBy = this.userId;
    this.addUpdateZoneModel.providerId = this.providerId;
    this.addUpdateZoneModel.zoneCode = this.zoneForm.value['zoneName'];
    this.addUpdateZoneModel.isActive = this.zoneForm.value['zoneIsActive'];
    this.providerZone.addUpdateZone(this.addUpdateZoneModel).subscribe(
      data => {
        if (data > 0) {
          this.closeZoneModel.nativeElement.click();
          this.getProviderZoneDropDown();
        }
      },
      error => {
        console.log(error);
      }

    );
  }

  openZoneModel() {
    this.zoneForm.setValue({
      'zoneName': '',
      'zoneIsActive': 1
    });
  }
}
