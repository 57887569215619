import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Globle } from './global';
import { ToastrService } from './toastr.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    appModel: any;
    constructor(private toastr: ToastrService,
        private globle: Globle) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headersConfig = {
            'Accept': 'application/json',
        };
        if (!(req.body instanceof FormData)) {
            headersConfig['Content-Type'] = 'application/json';
        }
        if (this.globle.cUser && this.globle.cUser.id) {
            headersConfig['Token'] = this.globle.cUser.authToken;
        }
        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request).pipe
            (
                catchError((error: HttpErrorResponse) => {
                    const message = error.error.Message ? error.error.Message : error.message;
                    //this.toastr.Error(message);
                    if(message=="User already register."){ }
                    else if(message=="Your email is already registered, please go to the highlighted log in area."){ }
                    else if(message=="Duplicate user found, Please check Email Address and Phone Number."){ }
                    else{this.toastr.Error(message);}
                    return throwError(message);
                })
            );
    }
}