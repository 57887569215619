import { Component, OnInit } from '@angular/core';
import { Maintenance } from 'src/Services/maintenance.services';
import { PaginationModel } from 'src/Models/paginationModel';
import { MaintenanceModel } from 'src/Models/maintenanceModel';
import { Router, ActivatedRoute } from '@angular/router';
import { movetotop, getIntParam, checkContainesURL, getEncryptedString } from 'src/app/Shared/commonMethods';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css']
})
export class UnderMaintenanceComponent implements OnInit {
  providerId = 0;
  upDowMessageTextCSS: string = '';
  upDowStartDateCSS: string = '';
  upDowEndDateCSS: string = '';
  upDowStatusCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDownModifiedByCSS: string = '';
  isAscending: boolean;
  isloading = false;

  listOfData: MaintenanceModel[];
  filterModel = new PaginationModel();

  totalRecord: any = -1;
  fromProvider: boolean = false;

  constructor(
    private maintenance: Maintenance,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private globle: Globle) {

    this.fromProvider = checkContainesURL(this.router, 'providers');
    this.providerId = getIntParam(this.activatedRoute.params, 'id');

    this.filterModel.orderBy = 'ModifiedDate';
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.filterModel.whereCondition = '';
    this.filterModel.providerId = this.providerId.toString();
    this.titleService.setTitle('Maintenance');
  }

  ngOnInit() {
    this.GetMaintenances();
  }

  GetMaintenances() {
    if (!this.fromProvider) {
      this.providerId = this.globle.cUser.providerId;
      this.filterModel.providerId = this.providerId.toString();
    }
    this.maintenance.GetMaintenances(this.filterModel).subscribe(
      data => {
        this.isloading = false;
        this.listOfData = data;
        
        const { getStatus } = this;
        if (this.listOfData != null && this.listOfData.length > 0) {
          this.totalRecord = this.listOfData[0].totalRecordCount;
          this.listOfData.forEach(x => x.statusName = getStatus(new Date(x.startDate), new Date(x.endDate)));
        }

      },
      error => {
        this.isloading = false;
      }
    );
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'messageText': this.upDowMessageTextCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'startDate': this.upDowStartDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'endDate': this.upDowEndDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'status': this.upDowStatusCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isActive': this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'modifiedDate': this.upDownModifiedByCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetMaintenances();
  }

  cleanCssClass() {
    this.upDowMessageTextCSS = '';
    this.upDowStartDateCSS = '';
    this.upDowEndDateCSS = '';
    this.upDowStatusCSS = '';
    this.upDowIsActiveCSS = '';
  }

  keywordFilters($event) {
    this.isloading = true;
    this.filterModel.whereCondition = $event.target.value;
    this.GetMaintenances();
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.GetMaintenances();
  }

  addMaintenances() {
    this.router.navigate(['/admin/maintenance/add']);
  }

  editMaintenances(id) {
    this.router.navigate(['/admin/maintenance/edit/' + id]);
  }

  getStatus(startDate: Date, endDate: Date) {
    const currentDate = new Date();
    if (startDate < currentDate && endDate > currentDate)
      return "In Progress";
    if (startDate > currentDate)
      return "Pending";
    if (endDate < currentDate)
      return "Done";
  }
}
