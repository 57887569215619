import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globle } from 'src/app/Shared/global';
import { BillingFilterModel } from 'src/Models/billingFilterModel';
import { SubscribersModel } from 'src/Models/subscribersModel';
import { UsersService } from 'src/Services/users.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.css']
})
export class SubscribersComponent implements OnInit {

  filterModel = new BillingFilterModel();
  listOfSubscribers: SubscribersModel[];
  providerId: string = '0';
  totalRecordsCount: number = 0;
  isAscending: boolean;
  isloading = false;
  recordsCount: number = -1;
  currentPage: number = 1;

  upDownAccountNumberCSS: string = '';
  upDownNameCSS: string = '';
  upDownEmailCSS: string = '';
  upDownCityCSS: string = '';
  upDownZoneNameCSS: string = '';
  upDownLocationNameCSS: string = '';
  upDownIsActiveCSS: string = '';
  upDownApplicationCSS: string = '';

  // Is ActiveDeactive
  dropdownListIsActiveDeactive = [];
  selectedItemsIsActiveDeactive = [];
  dropdownSettingsIsActiveDeactive = {};
  listOfIsActiveDeactive: string[] = [];

  dropdownListIsApplication = [];
  selectedItemsIsApplication = [];
  dropdownSettingsIsApplication = {};
  listOfIsApplication: string[] = [];

  constructor(private router: Router, private userService: UsersService, private titleService: Title,
    private globle: Globle,) {
    this.providerId = this.globle.cUser.providerId.toString();
    this.filterModel.providerIds = this.providerId;
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.filterModel.orderBy = 'Id desc';
    this.filterModel.isApplication = '1';
    this.titleService.setTitle('Subscribers');
  }

  ngOnInit() {    
    this.GetIsActiveDeactiveData();
    this.GetIsApplicationData()
    this.selectedItemsIsApplication = [];
    this.selectedItemsIsApplication.push({ 'id': 1, 'itemName': 'Yes' })
    this.listOfIsApplication.push('1');
    this.GetSubscriberByPaged();
  }

  GetSubscriberByPaged() {
    this.isloading = true;
    this.listOfSubscribers = [];
    this.userService.GetSubscriberByPaged(this.filterModel).subscribe(
      data => {
        this.isloading = false;
        this.listOfSubscribers = data;
        if (this.listOfSubscribers != null) {
          if (this.listOfSubscribers.length > 0) {
            this.totalRecordsCount = this.listOfSubscribers[0].totalRecordCount;
            this.recordsCount = this.totalRecordsCount;
          }
          else {
            this.totalRecordsCount = 0;
            this.recordsCount = 0;
          }
        }
        else {
          this.totalRecordsCount = 0;
          this.recordsCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.recordsCount = 0;
        console.log(error);
      }
    );
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'accountNumber': this.upDownAccountNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'name': this.upDownNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'emailId': this.upDownEmailCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'city': this.upDownCityCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'zoneName': this.upDownZoneNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'locationName': this.upDownLocationNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isActive': this.upDownIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isApplication': this.upDownApplicationCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;      
      default: break;
    }
    this.GetSubscriberByPaged();
  }

  cleanCssClass() {
    this.upDownAccountNumberCSS = '';
    this.upDownNameCSS = '';
    this.upDownEmailCSS = '';
    this.upDownCityCSS = '';
    this.upDownZoneNameCSS = '';
    this.upDownLocationNameCSS = '';
    this.upDownIsActiveCSS = '';
  }

  pageChanged($event) {
    this.filterModel.pageNumber = $event.page;
    this.GetSubscriberByPaged();
  }

  keywordFilters($event) {
    this.isloading = true;
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.filterModel.whereCondition = $event.target.value;
    this.GetSubscriberByPaged();
  }

  editSubscriber(userId) {
    this.router.navigate(['admin/subscriber/edit/' + userId]);
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onItemSelectIsActiveDeactive($event) {
    this.listOfIsActiveDeactive = [];
    if (this.listOfIsActiveDeactive != null) {
      if (this.listOfIsActiveDeactive.length > 0) {
        this.listOfIsActiveDeactive = this.removeElemetninArray(this.listOfIsActiveDeactive, $event.id)
        this.listOfIsActiveDeactive.push($event.id);
      }
      else {
        this.listOfIsActiveDeactive.push($event.id);
      }
    }

    this.filterModel.isActive = this.listOfIsActiveDeactive.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    if ($event.id == -1)
      this.filterModel.isActive = null;
    this.GetSubscriberByPaged();
  }

  OnItemDeSelectIsActiveDeactive($event) {
    if (this.listOfIsActiveDeactive != null) {
      if (this.listOfIsActiveDeactive.length > 0) {
        this.listOfIsActiveDeactive = this.removeElemetninArray(this.listOfIsActiveDeactive, $event.id)
      }
    }
    this.filterModel.isActive = this.listOfIsActiveDeactive.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetSubscriberByPaged();
  }
  onSelectAllIsActiveDeactive($event) {
    this.listOfIsActiveDeactive = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfIsActiveDeactive != null) {
        if (this.listOfIsActiveDeactive.length > 0) {
          this.listOfIsActiveDeactive = this.removeElemetninArray(this.listOfIsActiveDeactive, $event[iCounter].id)
          this.listOfIsActiveDeactive.push($event[iCounter].id);
        }
        else {
          this.listOfIsActiveDeactive.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.isActive = this.listOfIsActiveDeactive.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetSubscriberByPaged();
  }

  onDeSelectAllIsActiveDeactive($event) {
    this.listOfIsActiveDeactive = [];
    this.filterModel.isActive = '';
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetSubscriberByPaged();
  }

  GetIsActiveDeactiveData(): void {

    this.dropdownSettingsIsActiveDeactive = {
      singleSelection: true,
      text: 'Filter by Active',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No active available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

    this.dropdownListIsActiveDeactive.push({ 'id': -1, 'itemName': 'All' });
    this.dropdownListIsActiveDeactive.push({ 'id': 1, 'itemName': 'Yes' });
    this.dropdownListIsActiveDeactive.push({ 'id': 0, 'itemName': 'No' });

  }

  GetIsApplicationData(): void {

    this.dropdownSettingsIsApplication = {
      singleSelection: true,
      text: 'Filter by Application',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No application available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

    this.dropdownListIsApplication.push({ 'id': -1, 'itemName': 'All' });
    this.dropdownListIsApplication.push({ 'id': 1, 'itemName': 'Yes' });
    this.dropdownListIsApplication.push({ 'id': 0, 'itemName': 'No' });

  }

  onItemSelectIsApplication($event) {
    this.listOfIsApplication = [];
    if (this.listOfIsApplication != null) {
      if (this.listOfIsApplication.length > 0) {
        this.listOfIsApplication = this.removeElemetninArray(this.listOfIsApplication, $event.id)
        this.listOfIsApplication.push($event.id);
      }
      else {
        this.listOfIsApplication.push($event.id);
      }
    }

    this.filterModel.isApplication = this.listOfIsApplication.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    if ($event.id == -1)
      this.filterModel.isApplication = null;
    this.GetSubscriberByPaged();
  }

  OnItemDeSelectIsApplication($event) {
    if (this.listOfIsApplication != null) {
      if (this.listOfIsApplication.length > 0) {
        this.listOfIsApplication = this.removeElemetninArray(this.listOfIsApplication, $event.id)
      }
    }
    this.filterModel.isApplication = this.listOfIsApplication.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetSubscriberByPaged();
  }
  onSelectAllIsApplication($event) {
    this.listOfIsApplication = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfIsApplication != null) {
        if (this.listOfIsApplication.length > 0) {
          this.listOfIsApplication = this.removeElemetninArray(this.listOfIsApplication, $event[iCounter].id)
          this.listOfIsApplication.push($event[iCounter].id);
        }
        else {
          this.listOfIsApplication.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.isApplication = this.listOfIsApplication.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetSubscriberByPaged();
  }

  onDeSelectAllIsApplication($event) {
    this.listOfIsApplication = [];
    this.filterModel.isApplication = '';
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetSubscriberByPaged();
  }
}
