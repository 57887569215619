import { Component, OnInit } from '@angular/core';
import { BillingService } from 'src/Services/billing.services';
import { RateSetupHistoryModel } from 'src/Models/rateSetupHistory';
import { Globle } from 'src/app/Shared/global';
import { Router, ActivatedRoute } from '@angular/router';
import { getIntParam } from 'src/app/Shared/commonMethods';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rate-setup-history',
  templateUrl: './rate-setup-history.component.html',
  styleUrls: ['./rate-setup-history.component.css']
})
export class RateSetupHistoryComponent implements OnInit {

  providerId: any = 0;
  listOfProvider: RateSetupHistoryModel[];
  effEndDate: string = '';
  isDataLoad: boolean = false;
  isProgress: boolean = false;

  listOfDate: any = [];

  constructor(private billingService: BillingService,
    private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title,
    private globle: Globle) {
    this.providerId = getIntParam(this.activatedRoute.params, 'pid');
    this.titleService.setTitle('Rate Setup History');
  }

  ngOnInit() {
    // this.setDateFormat(new Date());
    this.GetRateSetupHistoryEffStartDate();
    // if (this.providerId > 0)
    //   this.GetRateSetupHistory();
  }

  GetRateSetupHistory() {
    this.isProgress = true;
    this.listOfProvider = [];
    this.billingService.GetRateSetupHistory(this.effEndDate, this.providerId).subscribe(
      data => {
        this.isProgress = false;
        this.listOfProvider = data;
        console.log(this.listOfProvider);
        if (this.listOfProvider != null) {
          if (this.listOfProvider.length == 0) {
            this.isDataLoad = true;
          }
          else
            this.isDataLoad = false;
        }
      },
      error => {
        this.isProgress = false;
        console.log(error);
      }
    );
  }

  setDateFormat(startDate: any) {
    if (startDate != null) {
      // let dd1 = startDate.getDate().toString();
      // if (parseInt(dd1.toString()) < 10)
      //   dd1 = '0' + dd1.toString();

      // let mm1 = (startDate.getMonth() + 1).toString();
      // if (parseInt(mm1.toString()) < 10)
      //   mm1 = '0' + mm1.toString();

      // this.effEndDate = startDate.getFullYear() + mm1 + dd1;
      this.effEndDate = startDate.currentTarget.value.toString().replace(/-/g, '');
      this.GetRateSetupHistory();
    }
  }

  redirectToBack() {
    this.router.navigate(['admin/rate/setup/' + this.providerId]);
  }

  GetRateSetupHistoryEffStartDate() {
    this.billingService.GetRateSetupHistoryEffStartDate(this.providerId).subscribe(
      data => {
        this.listOfDate = data;
        if (this.listOfDate != null) {
          if (this.listOfDate.length > 0) {
            this.effEndDate = this.listOfDate[0];
            this.GetRateSetupHistory();
          }
        }

      },
      error => {
        console.log(error);
      }
    );
  }

}
