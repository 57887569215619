import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingFilterModel } from "src/Models/billingFilterModel";
import { BillingService } from "src/Services/billing.services";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { InvoiceModel } from 'src/Models/invoiceModel';
import { ProviderModel } from 'src/Models/providerModel';

@Component({
  selector: 'app-invoice-listing',
  templateUrl: './invoice-listing.component.html',
  styleUrls: ['./invoice-listing.component.css']
})
export class InvoiceListingComponent implements OnInit {
  filterModel = new BillingFilterModel();
  listOfInvoice: InvoiceModel[];
  bsRangeValue: Date[];
  totalRecordsCount: number = 0;
  isProgress: boolean = false;
  isDataLoad: boolean = false;

  upDowProviderNameCSS: string = '';
  upDowInvoiceNoCSS: string = '';
  upDowInvoiceDateCSS: string = '';
  upDowBillStatusCSS: string = '';
  upDowCreatedByCSS: string = '';
  upDownlModifiedByCSS: string = '';
  upDownInvoiceAmountCSS: string = '';
  
  isAscending: boolean;

  dropdownProviderList = [];
  selectedProviderItems = [];
  dropdownProviderSettings = {};
  providerList: any[] = [];
  listOfProvider: ProviderModel[];
  deleteId: number = 0;

  constructor(private router: Router,
    private billingService: BillingService,
    private titleService: Title,
    private toastr: ToastrService,
  ) {
    this.titleService.setTitle('Invoices');
  }

  ngOnInit() {
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.filterModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) Desc';
    this.GetInvoiceByPaged();
    this.GetProvider();
  }

  GetInvoiceByPaged() {
    this.isProgress = true;
    this.listOfInvoice = [];
    this.billingService.GetInvoiceByPaged(this.filterModel).subscribe(
      data => {
        this.isProgress = false;
        this.listOfInvoice = data;
        if (data != null) {
          if (data.length == 0) {
            this.isDataLoad = true;
          }
          else {
            this.isDataLoad = false;
          }
          if (data.length > 0) {
            this.totalRecordsCount = data[0].totalRecordCount;
          }
        }
      },
      error => {
        console.log(error);
        this.isProgress = false;
      }
    );
  }


  redirectToManageInvoice(id: any) {
    if (id == 0)
      this.router.navigate(['admin/invoice/add']);
    else
      this.router.navigate(['admin/invoice/edit/' + id]);
  }

  setDateFormat($event) {
    let array = $event;
    if (array != null) {
      if (array.length > 0) {
        let dd = array[0].getDate().toString();
        if (parseInt(dd.toString()) < 10)
          dd = '0' + dd.toString();

        let mm = (array[0].getMonth() + 1).toString();
        if (parseInt(mm.toString()) < 10)
          mm = '0' + mm.toString();

        let dd1 = array[1].getDate().toString();
        if (parseInt(dd1.toString()) < 10)
          dd1 = '0' + dd1.toString();

        let mm1 = (array[1].getMonth() + 1).toString();
        if (parseInt(mm1.toString()) < 10)
          mm1 = '0' + mm1.toString();

        let sDate = array[0].getFullYear() + mm + dd;
        let eDate = array[1].getFullYear() + mm1 + dd1;

        //this.invoiceDateString = sDate;
        this.filterModel.pageNumber = 1;
        this.filterModel.effStartDate = sDate;
        this.filterModel.effEndDate = eDate;
        if (sDate != null && eDate != null)
          this.GetInvoiceByPaged();
      }
    }
  }

  clearDate() {
    this.bsRangeValue = [];
    this.filterModel.pageNumber = 1;
    this.filterModel.effStartDate = '';
    this.filterModel.effEndDate = '';
    this.GetInvoiceByPaged();
  }

  setBillStatus($event) {
    this.filterModel.pageNumber = 1;
    this.filterModel.billingStatus = $event.currentTarget.value;
    if ($event.currentTarget.value == 0)
      this.filterModel.billingStatus = '';
    this.GetInvoiceByPaged();
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.GetInvoiceByPaged();
  }

  cleanCssClass() {
    this.upDowProviderNameCSS = '';
    this.upDowInvoiceNoCSS = '';
    this.upDowInvoiceDateCSS = '';
    this.upDowBillStatusCSS = '';
    this.upDowCreatedByCSS = '';
    this.upDownlModifiedByCSS = '';
    this.upDownInvoiceAmountCSS = '';
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'providerName': this.upDowProviderNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'invoiceNo': this.upDowInvoiceNoCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'invoiceDate': this.upDowInvoiceDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'billingStatus': this.upDowBillStatusCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'createdByName': this.upDowCreatedByCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'modifiedByName': this.upDownlModifiedByCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'invoiceAmount': this.upDownInvoiceAmountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break; 
      default: break;
    }
    this.GetInvoiceByPaged();
  }

  GetProvider() {
    this.billingService.GetProvidersForBilling().subscribe(
      data => {
        this.listOfProvider = data;

        this.dropdownProviderList = [];
        if (this.listOfProvider != null) {
          if (this.listOfProvider.length > 0) {
            for (let iCounter = 0; iCounter < this.listOfProvider.length; iCounter++) {
              this.dropdownProviderList.push(
                { "id": this.listOfProvider[iCounter].id, "itemName": this.listOfProvider[iCounter].name }
              );
            }
          }
        }

        this.dropdownProviderSettings = {
          singleSelection: true,
          text: "Select Provider",
          selectAllText: 'Select All',
          unSelectAllText: 'Clear All',
          enableSearchFilter: true,
          enableCheckAll: false,
          badgeShowLimit: 1,
          noDataLabel: 'No provider available'
        };

      },
      error => {
        this.toastr.error(error, 'Error');
      }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e != element);
  }

  onItemSelect(item: any) {
    if (this.providerList != undefined) {
      this.providerList = this.removeElemetninArray(this.providerList, item['id']);
    }
    this.providerList.push(item['id']);
    this.filterModel.providerIds = this.providerList.join(',');
    this.GetInvoiceByPaged();
  }
  OnItemDeSelect(item: any) {
    if (this.providerList != undefined) {
      this.providerList = this.removeElemetninArray(this.providerList, item['id']);
    }

    this.filterModel.providerIds = this.providerList.join(',');
    this.GetInvoiceByPaged();
  }
  onSelectAll(items: any) {
    this.providerList = [];
    for (let iCounter = 0; iCounter < items.length; iCounter++) {
      this.providerList.push(items[iCounter]['id']);
    }
    this.filterModel.providerIds = '';
    this.GetInvoiceByPaged();
  }
  onDeSelectAll(items: any) {
    this.providerList = [];
    this.filterModel.providerIds = '';
    this.GetInvoiceByPaged();
  }

  setDeleteId(Id: any) {
    this.deleteId = Id;
  }

  deleteInvoice() {
    if (this.deleteId > 0) {
      this.billingService.DeleteInvoice(this.deleteId).subscribe(
        data => {
          if (data == 'Success') {
            this.toastr.success('Record deleted successfully', 'Success');
            this.deleteId = 0;
            this.GetInvoiceByPaged();
          }
        },
        error => {
          console.log(error);
        }
      );

    }
  }
}
