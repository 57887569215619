import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserModel } from 'src/Models/usersModel';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../../../Services/users.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globle } from 'src/app/Shared/global';
import { getIntParam, movetotop, checkContainesURL } from 'src/app/Shared/commonMethods';
import { AuthService } from 'src/app/Shared/auth.service';
import { LocationService } from 'src/Services/location.service';
import { encodeUriSegment } from '@angular/router/src/url_tree';
import { UserType } from 'src/app/Shared/AppEnums';
import { ProviderUserGroup } from 'src/app/Shared/AppEnums';
import { UserGroupModel } from 'src/Models/userGroupModel';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.css']
})
export class AddEditUserComponent implements OnInit {
  providerId = 0;
  _userID = 0;
  userForm: FormGroup;
  listOfUserGroup: UserGroupModel[] = [];
  isloading = false;
  pageTitle = 'Add User';
  submitted = false;
  userType = 0;
  //locationShow: boolean = true;
  adminTypeId: number
  //locations = [];
  fromProvider = false;
  // dropdownSettings = {};
  // selectedLocation = [];
  // locationText = '';
  // locationDisabled = false;
  @ViewChild('dropdownRef') dropdownRef: AngularMultiSelect;

  constructor(
    private _fb: FormBuilder,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private titleService: Title,
    private globle: Globle,
    private auth: AuthService,
    private locationService: LocationService
  ) {

    this.userForm = this._fb.group({
      id: [this._userID],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      middleName: [''],
      //userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email]],
      ssn: [''],
      userGroupId: ['', [Validators.required]],
      //locationId: ['', [Validators.required]],
      isActive: 1,
      saltKey: [''],
      providerId: 0,
      getAlert: 1
    });
  }

  get f() { return this.userForm.controls; }

  ngOnInit() {
    movetotop();
    this.titleService.setTitle('Manage User');
    this.fromProvider = checkContainesURL(this.router, 'providers');
    this.providerId = getIntParam(this.activatedRoute.params, 'pid');
    this._userID = getIntParam(this.activatedRoute.params, 'id');
    this.userType = getIntParam(this.activatedRoute.params, 'usertype');
    this.commonUserFunction();
    // if (this.providerId == 0) {
    //   this.userForm.get('locationId').setValidators([]);
    //   this.userForm.get('locationId').updateValueAndValidity();
    //   this.userForm.get('locationId').setValue('');
    // }
    // this.getProviderLocation();
    
    // this.dropdownSettings = {
    //   singleSelection: true,
    //   labelKey: "name",
    //   primaryKey: "id",
    //   text: "Select legacy company",
    //   enableSearchFilter: true,
    //   classes: "dropdownborders",
    //   noDataLabel: 'No legacy company available',
    //   showCheckbox: false,
    //   enableFilterSelectAll: false,
    //   disabled: false,
    //   //lazyLoading: true,
    //   //searchBy: ['name'],
    //   escapeToClose: true
    // };
  }

  commonUserFunction() {
    if (this._userID) {
      this.pageTitle = 'Edit User';
      this.Getuser();
    } else {
      if (this.userType > 0) {
        this.GetUserGroups(this.userType);
      }
      else {
        this.GetUserGroups(this.globle.cUser.userTypeId);
      }

    }
  }

  GetUserGroups(userTypeId: number): void {
    this.isloading = true;
    this.userService.GetUserGroups(userTypeId).subscribe(
      data => {
        this.listOfUserGroup = data.userGroups;

        if (this.listOfUserGroup != null) {
          if (this.listOfUserGroup.length > 0) {
            if (this.globle.cUser.userGroupId == 4 || this.globle.cUser.userGroupId == 5) {
              this.listOfUserGroup = this.listOfUserGroup.filter(m => m.id != 3)
              //this.onGroupChange(false);
            }
          }
        }
        this.isloading = false;
      }
    );
  }

  // getProviderLocation(): void {
  //   this.isloading = true;
  //   this.locationService.getProviderLocationDropdown(this.providerId).subscribe(
  //     data => {

  //       if (data != null) {
  //         this.locations.push({ 'id': 0, 'name': 'All' })
  //         for (let iCounter = 0; iCounter < data.length; iCounter++) {
  //           this.locations.push({ 'id': data[iCounter].id, 'name': data[iCounter].name })
  //         }

  //       }


  //       this.isloading = false;
  //       this.commonUserFunction();
  //     }
  //   );
  // }

  saveuser(userForm: any) {
    this.submitted = true;
    if (this.auth.checkValidation(userForm)) {
      this.userForm.value['providerId'] = this.providerId;
      var temp = this.userForm.value;

      if (temp.userGroupId == '0') {
        this.toastr.error('Please select user role', 'Error');
        return;
      }
      if (this.userForm.controls["emailId"].value != '') {
        let result = this.isValidMailFormat(userForm.controls.emailId);
        if (result != null) {
          this.toastr.error('Please provide a valid email', 'Error');
          return;
        }
      }
      
      // if (this.selectedLocation.length > 0) {
      //   temp.locationId = this.selectedLocation[0].id;
      // }
      if (this._userID == this.globle.cUser.id)
        temp.userGroupId = this.globle.cUser.userGroupId;
      temp['CreatedById'] = this.globle.cUser.id;
      temp['ModifiedById'] = this.globle.cUser.id;
      this.isloading = true;
      this.userService.addupdateuser(temp).subscribe(
        data => {
          if (data) {
            const msg = this._userID === 0 ? 'added' : 'updated';
            this.toastr.success('User ' + msg + ' successfully', 'Success');
            if (this.fromProvider) {
              this.router.navigate(['/admin/providers/users/' + this.providerId + '/' + this.userType]);
            }
            else {
              this.router.navigate(['/admin/users/' + this.providerId + '/' + this.userType]);
            }
          }
        }, error => {
          this.isloading = false;
        });
    }
  }

  canceluser() {
    if (this.fromProvider) {
      this.router.navigate(['/admin/providers/users/' + this.providerId + '/' + this.userType]);
    }
    else {
      this.router.navigate(['/admin/users/' + this.providerId + '/' + this.userType]);
    }
  }

  Getuser() {
    this.isloading = true;
    this.userService.GetUserFindByUserID(this._userID).subscribe(
      data => {

        this.userForm.setValue({
          id: data['id'],
          firstName: data['firstName'],
          lastName: data['lastName'],
          middleName: data['middleName'],
          //userName: ['', [Validators.required]],
          password: data['password'],
          emailId: data['emailId'],
          ssn: data['ssn'],
          isActive: data['isActive'],
          saltKey: data['saltKey'],
          providerId: data['providerId'],
          getAlert: data['getAlert'],
          userGroupId: data['userGroupId'],
          //locationId: [],
        });
        //this.selectedLocation = [];
        // if (this.locations) {
        //   if (this.locations.length > 0) {
        //     let tmpObject = this.locations.filter(x => x.id == data.locationId);
        //     if (tmpObject != null) {
        //       if (tmpObject.length > 0) {
        //         this.selectedLocation = this.removeElemetninArray(this.selectedLocation, tmpObject[0].id);
        //         this.selectedLocation.push({ 'id': tmpObject[0].id, 'name': tmpObject[0].name })
        //       }
        //     }
        //   }
        // }

        this.GetUserGroups(data.userTypeId);
        this.isloading = false;
        //this.onGroupChange(true);
        if (this._userID == this.globle.cUser.id) {
          this.userForm.get('userGroupId').disable();
        }
      }
    );
  }

  // onGroupChange(isEdit) {
  //   //this.selectedLocation = [];
  //   if (this.globle.cUser.userGroupId != 4 && this.globle.cUser.userGroupId != 5) {
  //     if (this.userType == UserType.Provider) {
  //       if (this.userForm.get('userGroupId').value == ProviderUserGroup.Admin) {
  //         this.selectedLocation = undefined;
  //         this.dropdownSettings["disabled"] = true;
  //         this.userForm.controls['locationId'].setValue('');
  //         this.locationShow = false;
  //         this.userForm.get('locationId').disable();
  //         this.locationDisabled = false;

  //       }
  //       else {
  //         if (!isEdit) {
  //           this.userForm.controls['locationId'].setValue('');
  //           this.dropdownSettings["disabled"] = false;
  //         }

  //         this.userForm.get('locationId').enable();
  //         this.locationDisabled = false;
  //       }
  //     }
  //   }
  //   else {

  //     this.locationShow = true;
  //     this.dropdownSettings["disabled"] = true;
  //     if (this.locations) {
  //       if (this.locations.length > 0) {
  //         let tmpObject = this.locations.filter(x => x.id == this.globle.cUser.locationId);
  //         if (tmpObject != null) {
  //           if (tmpObject.length > 0) {
  //             this.selectedLocation = this.removeElemetninArray(this.selectedLocation, tmpObject[0].id);
  //             this.selectedLocation.push({ 'id': tmpObject[0].id, 'name': tmpObject[0].name })
  //           }
  //         }
  //       }
  //     }
  //     //  this.userForm.controls['locationId'].setValue(this.globle.cUser.locationId);
  //     this.userForm.get('locationId').disable();
  //     this.locationDisabled = true;

  //   }

  //   if (this.globle.cUser.locationId == '0') {
  //     this.dropdownSettings["disabled"] = false;
  //     this.locationDisabled = false;
  //   }

  //   this.dropdownSettings = Object.assign({}, this.dropdownSettings);

  // }

  onItemSelect($event) {

  }

  OnItemDeSelect($event) {

  }

  removeElemetninArray(array, element) {
    return array.filter(e => e.id != element);
  }

  isValidMailFormat(control: FormControl) {
    //let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    let EMAIL_REGEXP = /^[a-z0-9_.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { "Please provide a valid email": true };
    }
    return null;
  }

}
