import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { LocationService } from 'src/Services/location.service';
import { PaginationModel } from 'src/Models/paginationModel';
import { Globle } from 'src/app/Shared/global';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { LocationSearchModel } from 'src/Models/locationsearchModel';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  providerId: number = 0;
  listProviderLocation: any = [];
  pagination: PaginationModel = new PaginationModel();
  locationSearchModel: LocationSearchModel = new LocationSearchModel();
  isloading: boolean = false;
  Id: number = 0;
  isAscending: boolean;
  searchTextChanged = new Subject<string>();
  pageSize: number = 0;
  pageIndex: number = 0;
  totalRecordsCount: number = -1;
  upDowCodeCSS: string = '';
  upDowNameCSS: string = '';
  upDowManagerCSS: string = '';
  upDowStateCSS: string = '';
  upDowCityCSS: string = '';
  // upDowCreatedCSS: string = '';
  upDowModifiedCSS: string = '';
  upDowIsActiveCSS: string = '';

  constructor
    (
      private router: Router,
      private locationService: LocationService,
      private globle: Globle,
      private titleService: Title
    ) {
    this.pagination.orderBy = 'Name ASC';
    this.pagination.pageNumber = 1;
    this.pagination.pageSize = 10;
    this.pagination.userId = this.globle.cUser.id.toString();
    this.titleService.setTitle("Legacy Company");
  }

  ngOnInit() {
    movetotop();
    this.providerId = this.globle.cUser.providerId;
    this.Id = (this.globle.cUser.userGroupId == 4 || this.globle.cUser.userGroupId == 5) ? +this.globle.cUser.locationId : 0;
    this.getProviderLocation();
    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap(search => this.locationService.getProviderLocation(
        this.Id, this.providerId, this.pagination
      ))

    ).subscribe((data) => {
      this.assignData(data);
    });
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.pagination.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';
    switch (sortBy) {
      case 'locationCode': this.upDowCodeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'name': this.upDowNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'managerName': this.upDowManagerCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'stateName': this.upDowStateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'city': this.upDowCityCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      // case 'createdByName': this.upDowCreatedCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'modifiedByName': this.upDowModifiedCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isActive': this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.getProviderLocation();
  }


  cleanCssClass() {
    this.upDowCodeCSS = '';
    this.upDowNameCSS = '';
    this.upDowManagerCSS = '';
    this.upDowStateCSS = '';
    this.upDowCityCSS = '';
    // this.upDowCreatedCSS = '';
    this.upDowModifiedCSS = '';
    this.upDowIsActiveCSS = '';
  }

  keywordFilters($event) {
    this.isloading = true;
    this.pagination.pageNumber = 1;
    this.pagination.keywordFilter = $event.target.value;
    this.searchTextChanged.next($event.target.value);
  }


  getProviderLocation() {
    this.isloading = true;
    this.locationService.getProviderLocation(this.Id, this.providerId, this.pagination).subscribe(data => {
      this.assignData(data);
    });
  }

  assignData(data) {
    this.isloading = false;
    this.listProviderLocation = data;
    this.totalRecordsCount = 0;
    if (this.listProviderLocation && this.listProviderLocation.length > 0) {
      this.totalRecordsCount = this.listProviderLocation[0].totalRecords;
    }
  }

  editProviderlocation(Id: number) {
    this.Id = Id;
    this.router.navigate(['admin/location/add/' + this.Id])
  }

  addLocation() {
    this.router.navigate(['admin/location/add/0']);
  }
  pageChanged($event: any) {
    this.pagination.pageNumber = parseInt($event.page);
    this.getProviderLocation();
  }
  ZipCodes(id: number) {
    this.router.navigate(['admin/location/zipcodes/' + id]);
  }
}
