import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import * as FileSaver from 'file-saver';
import { Globle } from 'src/app/Shared/global';
import { ApiStatus } from 'src/Models/apiStatus';
import { AnalyticalReportFilterModel, AnalyticalReportModel } from 'src/Models/report.model';
import { HtmlToPdfService } from 'src/Services/html-to-pdf.service';
import { LocationService } from 'src/Services/location.service';
import { ReportService } from 'src/Services/report.service';

@Component({
  selector: 'app-analytical-report',
  templateUrl: './analytical-report.component.html',
  styleUrls: ['./analytical-report.component.css']
})
export class AnalyticalReportComponent implements OnInit {
  //listOflocation;
  poviderId = 0;
  isloading: boolean;
  analyticModel = new AnalyticalReportModel();
  filter = new AnalyticalReportFilterModel();
  listOfApiStatus: ApiStatus[];
  yearList: number[];
  data: any;
  options: any;
  // dropdownSettings = {};
  // selectedLocation;
  // locationDisabled = false;
  // locationText = '';

  @ViewChild('dropdownRef') dropdownRef: AngularMultiSelect;
  constructor(private locationService: LocationService,
    private globle: Globle,
    private reportService: ReportService,
    private titleService: Title, private htmlToPdf: HtmlToPdfService
  ) {
    this.titleService.setTitle('Analytical Report');
  }

  ngOnInit() {
    this.filter.ReportOption = 1;
    //this.filter.LocationId = (this.globle.cUser.userGroupId == 4 || this.globle.cUser.userGroupId == 5) ? +this.globle.cUser.locationId : 0;
    this.filter.ViewBy = 2;
    this.fillYearList();
    // this.dropdownSettings = {
    //   singleSelection: true,
    //   labelKey: "name",
    //   primaryKey: "id",
    //   text: "All",
    //   enableSearchFilter: true,
    //   classes: "dropdownborders",
    //   noDataLabel: 'No location available',
    //   showCheckbox: false,
    //   enableFilterSelectAll: false,
    //   lazyLoading: true,
    //   searchBy: ['name'],
    //   escapeToClose: true
    // };
    this.getReportDetails();

    // if (this.globle.cUser.userGroupId == 4 || this.globle.cUser.userGroupId == 5) {
    //   this.locationText = this.globle.cUser.locationName;
    //   this.locationDisabled = true;
    //   this.getReportDetails();
    // }
    // else {
    //   this.getProviderLocationDropdown();
    // }

    // if (this.globle.cUser.locationId == '0') {
    //   this.getProviderLocationDropdown();
    //   this.locationDisabled = false;
    // }

  }

  fillYearList() {
    this.filter.FromYear = new Date().getFullYear();
    this.yearList = [];
    for (let index = 2020; index <= this.filter.FromYear; index++) {
      this.yearList.push(index);
    }
  }

  changeOption() {
    if (this.filter.ReportOption == 3) {
      this.filter.ToYear = new Date().getFullYear()
    }
    else {
      this.filter.ToYear = 0;
    }
    this.getReportDetails();

  }

  onItemSelect(item: any) {
    if (item) {
      this.filter.LocationId = item.id;
    }
    this.getReportDetails();
    this.dropdownRef.closeDropdown()

  }
  OnItemDeSelect(item: any) {
    this.filter.LocationId = 0;
    this.getReportDetails();
    this.dropdownRef.closeDropdown()
  }

  getReportDetails() {
    this.isloading = true;
    this.reportService.AnalyticReport(this.filter).subscribe(data => {
      this.isloading = false;
      
      this.analyticModel = data;
      this.analyticModel.new_Approve_Count = data["new_Approve_Count"];
      this.analyticModel.reNew_Reject_Count = data["reNew_Reject_Count"];
      this.analyticModel.Total_Count = data["total_Count"];
      if (data.data) {
        var labels = data.data.map(x => x.viewBy);
        var new_Approve_Count = data.data.map(x => x.new_Approve_Count);
        var reNew_Reject_Count = data.data.map(x => x.reNew_Reject_Count);
        this.data = {
          labels: labels,
          datasets: [
            {
              label: this.filter.ViewBy == 1 ? 'New' : 'Approved',
              data: new_Approve_Count,
              backgroundColor: this.filter.ViewBy == 1 ? '#42A5F5' : '#9CCC65',
              borderColor: this.filter.ViewBy == 1 ? '#1E88E5' : '#7CB342',
            },
            {
              label: this.filter.ViewBy == 1 ? 'Re-Certify' : 'Rejected',
              data: reNew_Reject_Count,
              backgroundColor: this.filter.ViewBy == 1 ? '#9CCC65' : '#880015',
              borderColor: this.filter.ViewBy == 1 ? '#7CB342' : '#880015',
            }
          ]
        }
      }
      this.options = {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value) { if (Number.isInteger(value)) { return value; } },
              stepSize: 1
            }
          }]
        }
      }

    }, error => {
      this.isloading = false;
    });
  }

  // getProviderLocationDropdown() {
  //   this.isloading = true;
  //   this.locationService.getProviderLocationDropdown(this.globle.cUser.providerId).subscribe(data => {
  //     this.listOflocation = data;
  //     if (this.filter.LocationId > 0)
  //       this.selectedLocation = this.listOflocation.filter(x => x.id == this.filter.LocationId);
  //     this.getReportDetails();
  //   }, error => {
  //     this.isloading = false;
  //   });
  // }

  exportToExcel() {
    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table>";
    //html+="<tr class='mainhead'><td colspan='3'>Location:" + ((this.selectedLocation != undefined && this.selectedLocation.length > 0) ? this.selectedLocation[0].name : 'All') + "</td></tr>";
    html += "<tr class='mainhead'>    <td colspan='3'> From Year: " + this.filter.FromYear + " To: " + (this.filter.ToYear != undefined ? this.filter.ToYear : '') + "</td>  </tr>";
    html += document.getElementById("analyticalReporthead").innerHTML + " " + document.getElementById("analyticalReportbody").innerHTML;
    html += "<tr><td style='font-weight: bold;'>Total</td><td>" + this.analyticModel.new_Approve_Count + "</td><td>" + this.analyticModel.reNew_Reject_Count + "</td></tr>"
    html += "</table>";
     var ctx = { worksheet: "AnaylyticalReport.xls" || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "AnaylyticalReport.xls");
  }

  exportToPdf() {
    this.isloading = true;
    var canvas = document.getElementsByTagName("canvas");
    var chartImage = canvas[0].toDataURL("image/png");
    let html = "<div class='col-md-12'><table  style='width:100%'>";
    //html+="<tr><td colspan='3' style='font-weight: bold;'>Location:" + ((this.selectedLocation != undefined && this.selectedLocation.length > 0) ? this.selectedLocation[0].name : 'All') + "</td></tr>";
    html += "<tr><td colspan='3' style='font-weight: bold;'> From Year: " + this.filter.FromYear + " To: " + (this.filter.ToYear != undefined && this.filter.ToYear != 0 ? this.filter.ToYear : '') + "</td></tr>";
    html += document.getElementById("analyticalReporthead").innerHTML + " " + document.getElementById("analyticalReportbody").innerHTML;
    html += "<tr><td style='font-weight: bold;border:1px solid #f4f4f4'>Total</td><td style='border:1px solid #f4f4f4'>" + this.analyticModel.new_Approve_Count + "</td><td style='border:1px solid #f4f4f4'>" + this.analyticModel.reNew_Reject_Count + "</td></tr>"
    html += "</table>";
    html += "</div>";
    this.htmlToPdf.CreatePDF(html, chartImage).
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadFile('AnaylyticalReport.pdf', data['url'], data['fileName']);
        }
      });

  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        this.htmlToPdf.DeletePDF(orignalFileName).subscribe(
          data => {
          },
        );
        this.isloading = false;
      }
    );
  }
}
