import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/Shared/auth.service';
import { LocationService } from '../../../Services/location.service';
import { ToastrService } from 'ngx-toastr';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { AppConsts } from '../../Shared/AppConsts';

@Component({
  selector: 'app-zipcode',
  templateUrl: './zipcode.component.html',
  styleUrls: ['./zipcode.component.css']
})
export class ZipcodeComponent implements OnInit {

  zipcodeForm: FormGroup;
  submitted: boolean = false
  isloading: boolean = false;
  listProviderLocationZipcodes: any = [];
  LocationId: number = 0;
  searchTextChanged = new Subject<string>();
  constructor(private auth: AuthService, private _fb: FormBuilder, private locationService: LocationService, private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router, ) {
  }

  ngOnInit() {
    movetotop();
    this.LocationId = getIntParam(this.activatedRoute.params, 'id');
    this.InitForm();
    this.GetZipCodeList();
    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap(search => this.locationService.GetZipCodeList({
        LocationId: this.LocationId,
        keywordFilter: search
      }
      ))
    ).subscribe((data) => {
      this.isloading = false;
      this.listProviderLocationZipcodes = data;
    });
  }

  keywordFilters($event) {
    this.isloading = true;
    this.searchTextChanged.next($event.target.value);
  }

  InitForm() {
    this.zipcodeForm = this._fb.group({
      locationId: [this.LocationId],
      zipcode: ['', [Validators.required, Validators.pattern(AppConsts.NUMBER_REGEXP)]]
    });
  }

  get f() { return this.zipcodeForm.controls; }

  saveZipcode() {
    this.submitted = true;
    if (this.auth.checkValidation(this.zipcodeForm)) {
      this.locationService.AddZipCode(this.zipcodeForm.value).subscribe(data => {
        this.submitted = false;
        this.ClosePopup('closeZipcodeModel');
        this.toastr.success("Zipocde has been inserted successfully", "Success");
        this.GetZipCodeList();
        this.InitForm();
      }, error => {
        this.submitted = false;
        this.InitForm();
      });
    }
  }

  GetZipCodeList() {
    this.isloading = true;
    this.locationService.GetZipCodeList({
      LocationId: this.LocationId,
      keywordFilter: ''
    }).subscribe(data => {
      this.isloading = false;
      this.listProviderLocationZipcodes = data;
    }, error => {
      this.isloading = false;
    });
  }

  ClosePopup(controlName: string) {
    let element: HTMLElement = document.getElementById(controlName) as HTMLElement;
    element.click();
  }

  deleteZipcode(id: number) {
    this.isloading = true;
    this.locationService.DeleteZipCode(id).subscribe(data => {
      this.toastr.success("Zipocde has been deleted successfully", "Success");
      this.isloading = false;
      this.GetZipCodeList();
    }, error => {
      this.isloading = false;
    });
  }
  showZipCodeModal() {
    this.InitForm();
  }
}
