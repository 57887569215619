import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { FormType, SubscriberApplicationStatus } from 'src/app/Shared/AppEnums';
import { checkContainesURL, movetotop, enumValue } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';
import { ToastrService } from 'src/app/Shared/toastr.service';
import { environment } from 'src/environments/environment';
import { PaginationModel } from 'src/Models/paginationModel';
import { DocumentService } from 'src/Services/document.service';
import { NewRegistrationModel } from '../../../Models/providerModel';
import { SubscriberMessageModel } from '../../../Models/subscribeModel';
import { ProviderService } from '../../../Services/provider.service';
import { SubscriberService } from '../../../Services/subscriber.service';
import { AuthService } from '../../Shared/auth.service';

@Component({
  selector: 'app-newregistration',
  templateUrl: './newregistration.component.html',
  styleUrls: ['./newregistration.component.css', '../../../assets/css/status.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewregistrationComponent implements OnInit {
  providerId = 0;
  filterNewRegistrationModel: any = {};
  applicationStatus: Array<number> = [1];
  newRegistrationList: NewRegistrationModel[];
  searchTextChanged = new Subject<string>();
  messageList: SubscriberMessageModel[];
  pagination = new PaginationModel();
  isloading = false;
  totalRecordsCount = -1;
  recertify = false;
  display = false;
  statusList = enumValue(SubscriberApplicationStatus);
  isMessage = false;
  upDownAccountNumberCSS = '';
  upDownNameCSS = '';
  upDownApplicationNumberCSS = '';
  upDownDateCSS = '';
  upDownPhoneCSS = '';
  upDownEmailCSS = '';
  isAscending: boolean;
  statusCount;
  constructor(
    private titleService: Title,
    private globle: Globle,
    private providerService: ProviderService,
    private subscriberService: SubscriberService,
    private router: Router
  ) {
    this.newRegistrationList = [];
    this.pagination.orderBy = 'CreatedDate DESC';
    this.upDownDateCSS = 'fa fa-arrow-up';
  }

  ngOnInit() {
    movetotop();
    this.recertify = checkContainesURL(this.router, 'recertify');
    this.titleService.setTitle(this.recertify ? 'Recertify' : 'New Registration');
    this.pagination.pageSize = 10;
    this.pagination.pageNumber = 1;
    this.getNewRegistrationList();
    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap(search => this.providerService.GetRegistrationList(this.filterModel()))
    ).subscribe((data) => {
      this.assignData(data);
    }, error => {
      this.isloading = false;
    });
  }

  getNewRegistrationList() {
    this.isloading = true;
    this.providerService.GetRegistrationList(this.filterModel()).subscribe(data => {
      this.assignData(data);
    });
  }

  assignData(data) {
    this.isloading = false;
    this.newRegistrationList = data.item1;
    this.statusCount = data.item2;
    this.totalRecordsCount = 0;
    if (this.newRegistrationList.length > 0) {
      this.totalRecordsCount = this.newRegistrationList[0].totalRecords;
    }
  }

  filterModel(): {} {
    return this.filterNewRegistrationModel = {
      ProviderId: this.globle.cUser.providerId,
      FormType: this.recertify ? FormType['Re-Certify'] : FormType['New Registration'],
      ApplicationStatus: this.applicationStatus ? this.applicationStatus.join(',') : null,
      ...this.pagination,
      FromDate: null,
      ToDate: null,
      LocationId: this.globle.cUser.userGroupId !== 3 ? this.globle.cUser.locationId : null
    };
  }

  pageChanged($event) {
    this.pagination.pageNumber = $event.page;
    this.getNewRegistrationList();
  }


  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.pagination.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'locationId': this.upDownAccountNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'firstName': this.upDownNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'applicationNumber': this.upDownApplicationNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'createdDate': this.upDownDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'phoneNo': this.upDownPhoneCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'emailId': this.upDownEmailCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.getNewRegistrationList();
  }

  cleanCssClass() {
    this.upDownAccountNumberCSS = '';
    this.upDownApplicationNumberCSS = '';
    this.upDownNameCSS = '';
    this.upDownDateCSS = '';
    this.upDownPhoneCSS = '';
    this.upDownEmailCSS = '';
  }


  keywordFilters($event) {
    this.isloading = true;
    this.pagination.pageNumber = 1;
    this.pagination.pageNumber = 1;
    this.pagination.keywordFilter = $event.target.value;
    this.searchTextChanged.next($event.target.value);
  }

  getMessage(subscriberId: number) {
    this.isMessage = true;
    this.subscriberService.GetMessage(subscriberId).subscribe(
      data => {
        this.messageList = data;
        this.display = true;
        this.isMessage = false;
      }, error => {
        this.isloading = false;
      });
  }

  changeApplicationStatus(status) {
    const index = this.applicationStatus.findIndex(x => x === status);
    if (index === -1) {
      this.applicationStatus.push(status);
    } else {
      this.applicationStatus.splice(index, 1);
    }
    this.getNewRegistrationList();
  }

  getStringApplicationStatus(id: number): any {
    return SubscriberApplicationStatus[id];
  }

  downloadPDF(fileURL: string) {
    window.open(`${environment.apiURL}${fileURL}`, '_blank');
  }

  generateReport(id: number) {
    if (!this.recertify) {
      this.router.navigate(['admin/newregistration/review/' + id]);
    } else {
      this.router.navigate(['admin/recertify/review/' + id]);
    }
  }

  getStatusCount(id) {
    if (this.statusCount) {
      switch (id) {
        case 0:
          return this.statusCount.inProgressCount;
        case 1:
          return this.statusCount.pendingCount;
        case 2:
          return this.statusCount.inReviewCount;
        case 3:
          return this.statusCount.rejectCount;
        case 4:
          return this.statusCount.resubmissionCount;
        case 5:
          return this.statusCount.approveCount;
        case 6:
          return this.statusCount.submitDocumentCount;
      }
    } else { return 0; }
  }

}

