import { Injectable } from '@angular/core';
import { ToastrService as Toast } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(private messageService: Toast) {

  }

  Success(message: string, summary: string = '') {
    this.messageService.clear();
    this.messageService.success(message, summary);
  }

  Error(message: string, summary: string = '') {
    this.messageService.error(message, summary);
  }

  Warning(message: string, summary: string = '') {
    this.messageService.warning(message, summary);
  }
}
