import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { AuthService } from 'src/app/Shared/auth.service';
import { StateService } from '../../../Services/state.service';
import { ProviderService } from '../../../Services/provider.service';
import { ProviderModel } from 'src/Models/providerModel';
import { DocumentType } from 'src/app/Shared/AppEnums';
import { Globle } from 'src/app/Shared/global';
import { DocumentService } from 'src/Services/document.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-companyprofile',
  templateUrl: './companyprofile.component.html',
  styleUrls: ['./companyprofile.component.css']
})
export class CompanyprofileComponent implements OnInit {

  Id: number = 0;
  pageTitle = 'Add Provider';
  providerForm: FormGroup;
  //TO-DO Remove unnecessary variable
  statelist = [];
  providerStatelist = [];
  isloading = false;
  isloadingstates = false;
  submitted = false;
  selectedAllState = false;
  companyProfile: ProviderModel = new ProviderModel();
  formdata: FormData = new FormData();
  url;
  document;

  allowedMimeType = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  uploaderQueue: Array<any> = [];

  private providerLogoInfo: any;

  constructor(
    private _fb: FormBuilder,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService,
    private stateService: StateService,
    private providerService: ProviderService,
    private globle: Globle,
    private _documentService: DocumentService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.Id = getIntParam(this.activatedRoute.params, 'id');
    this.document = {
      documentTypeId: DocumentType.ProviderLogo,
      moduleId: this.globle.cUser.providerId
    };
    if (this.Id > 0) {
      this.titleService.setTitle('Company Profile');
      this.pageTitle = 'Company Profile';
      this.getProvider();
    }
  }

  getProvider() {
    this.isloading = true;
    this.providerService.GetProvider(this.Id).subscribe(data => {
      this.isloading = false;
      this.companyProfile = data;
    }, error => {
      this.isloading = false;
    });
    //get Provider Logo
    this._documentService.GetDocuments(this.document).subscribe(data => {
      if (data.length > 0) {
        this.providerLogoInfo = data;
        this.url = environment.apiURL + data[0].path + data[0].fileName;
      }
    },
      error => {
        this.isloading = false;
      });
  }

  saveProvider() {
    this._documentService.InsertDocument(this.formdata).subscribe(data => {
      this.isloading = false;
      this.toastr.success('Logo has been updated successfully', 'Success');
    }, error => {
      this.isloading = false;
    }
    );
  }

  validateFile(fileList) {
    if (fileList.length > 0) {
      this.uploaderQueue = [];
      for (let index = 0; index < fileList.length; index++) {
        const element = fileList[index];
        if (this.allowedMimeType.filter(x => x == element.type).length > 0) {
          this.uploaderQueue.push({ name: element.name, value: element });
          this.formdata = new FormData();
          this.formdata.append('document', JSON.stringify(this.document));
          this.formdata.append('file', this.uploaderQueue[0].value);
          const reader = new FileReader();
          reader.readAsDataURL(this.uploaderQueue[0].value as any); // read file as data url
          reader.onload = (event) => { // called once readAsDataURL is completed
            this.url = event.target.result;
          };
          this.uploaderQueue = [];
        } else {
          this.toastr.error('Document is not supported', 'Error');
        }
      }
    }
  }
  removeFileFromQueue(index) {
    this.uploaderQueue.splice(index, 1);
  }

  cancel() {
    this.router.navigate(['/admin/dashboard']);
  }

  deleteImage() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Logo?',
      accept: () => {
        this._documentService.DeleteDocument(this.providerLogoInfo[0].id).subscribe(
          data => {
            if (data) {
              this.url = "";
              this.toastr.success('Logo has been deleted successfully', 'Success');
            }
          },
          errorCode => {
            this.toastr.error('Unable to remove Logo', 'Error');
          }
        );
      }
    });
  }
}
