import { Injectable } from '@angular/core';
import { UserModel } from 'src/Models/usersModel';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public getToken(): string {
    const UserInfo = JSON.parse(sessionStorage.getItem('cUser'));
    if (UserInfo) {
      return UserInfo.authToken;
    }

  }

  public getUserInfo(): UserModel {
    const UserInfo = JSON.parse(sessionStorage.getItem('cUser'));
    if (UserInfo) {
      return UserInfo as UserModel;
    }
  }

  public checkValidation(form): boolean {
    if (!form.valid) {
      return false;
    }
    return true;
  }


}
